export default class SmoothScroll {
  constructor() {
    this.$targets = [...document.querySelectorAll('a[href^="#"]')]
    if(!this.$targets.length) return;
    this.init();
  }

  init() {
    this.onClick();
  }

  onClick() {
    this.$targets.forEach(target => {
      target.addEventListener('click', (e) => {
        const href = e.currentTarget.getAttribute('href')
        e.preventDefault()
        e.stopImmediatePropagation()
        gsap.to(window, {
          duration: 1,
          scrollTo: href === '#' ? 0 : href,
          ease: 'power2.out',
        })
      })
    });
  }
}
