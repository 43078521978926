import flatpickr from 'flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import holiday from 'holiday-jp'

export default class DatePicker {
  constructor() {
    this.$date = document.querySelectorAll('[data-datepicker]')
    this.$time = document.querySelectorAll('[data-datepicker-time]')

    this.$date.forEach(this.initDate.bind(this))
    this.$time.forEach(this.initTime.bind(this))
  }

  initDate($elm) {
    const type = $elm.dataset.datepicker
    let option = {
      locale: Japanese,
      disableMobile: true
    }
    if (type === 'group') {
      option.minDate = new Date().fp_incr(31)
      option.maxDate = new Date().fp_incr(365)
      const holidays = holiday.between(option.minDate, option.maxDate)
      const holidayArray = holidays.map((day) => day.date)
      option.disable = [
        { from: '2024-03-23', to: '2024-03-31' },
        { from: '2024-07-20', to: '2024-09-01' },
        { from: '2024-12-20', to: '2025-01-07' },
        ...holidayArray,
        function (currentDate) {
          const day = currentDate.getDay()
          return day === 0 || day === 6
        }
      ]
    }

    // console.log(option)
    flatpickr($elm, option)
  }

  initTime($elm) {
    flatpickr($elm, {
      locale: Japanese,
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true,
      disableMobile: true
    })
  }
}
