// import Post from './Post'
import ContactPost from './ContactPost'
import Validate from './Validate'

export default class Contact {
  constructor() {
    // new Post()
    new ContactPost()
    new Validate()
  }
}
