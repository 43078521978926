import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { config } from '../core';

export default class InfiniteCarousel {
  get DEFAULT_OPTIONS() {
    return {
      type: 'loop',
      autoWidth: true,
      arrows: false,
      pagination: false,
      drag: 'free',
      autoScroll: {
        speed: 0.5,
        rewind: false,
        pauseOnHover: false,
      },
    };
  }

  constructor() {
    this.$elements = [...document.querySelectorAll('[data-infinite-carousel]')]
    if (!this.$elements.length) return;
    this.createSlider();
  }

  createSlider() {
    this.$elements.forEach((el) => {
      const gapPc = parseInt(el.dataset.gapPc, 10) || 0;
      const gapSp = parseInt(el.dataset.gapSp, 10) || 0;
      const options = {
        ...this.DEFAULT_OPTIONS,
        gap: gapPc ?? 0,
        breakpoints: {
          [config.viewport.sm]: {
            gap: gapSp ?? 0,
          },
        },
      };
      const infiniteCarousel = new Splide(el, options);
      infiniteCarousel.mount({ AutoScroll });
    });
  }
}