export default class Accordion {
  get CLASSES() {
    return {
      isOpened: 'is-opened',
    };
  }

  constructor() {
    this.$elements = [...document.querySelectorAll('[data-accordion-el="trigger"]')];
    if (!this.$elements.length) return;
    this.$accordion;
    this.$content;
    this.init();
  }

  init() {
    this.onClick();
  }

  onClick() {
    this.$elements.forEach(el => {
      el.addEventListener('click', () => {
        this.$accordion = el.closest('[data-accordion]');
        this.$content = this.$accordion.querySelector('[data-accordion-el="content"]');
        this.toggle(this.$accordion);
      });
    });
  }

  toggle(target) {
    target.classList.contains(this.CLASSES.isOpened) ? this.hide(target) : this.show(target);
  }

  show(target) {
    target.classList.add(this.CLASSES.isOpened);
    gsap.to(this.$content, {
      height: 'auto',
      duration: 0.3,
      ease: 'power2.out"',
    })
  }

  hide(target) {
    target.classList.remove(this.CLASSES.isOpened);
    gsap.to(this.$content, {
      height: 0,
      duration: 0.3,
      ease: 'power2.out"',
    })
  }
}
