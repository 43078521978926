export default class ContactPost {
  constructor() {
    this.$form = document.querySelector(`[data-form]`)
    if (!this.$form) return
    this.$sending = document.querySelector(`[data-modal='sending']`)
    this.$error = document.querySelector(`[data-modal='error']`)
    this.$complete = document.querySelector(`[data-modal='complete']`)
    this.$completeContent = document.querySelectorAll(
      `[data-complete='content']`
    )
    this.$contactType = document.querySelector(`[data-form-contact-type]`)
    this.$contactTypeSelect = document.querySelectorAll(`[data-type-select]`)
    this.$contactTypeCategory = document.querySelector('[name="カテゴリー"]')
    this.$submit = this.$form.querySelector(`[data-validate-submit]`)
    this.formType = this.$form.dataset.form

    this.fadeIn = this.fadeIn.bind(this)
    this.fadeOut = this.fadeOut.bind(this)
    this.close = this.close.bind(this)
    this.errorClose = this.errorClose.bind(this)
    this.modalContentClick = this.modalContentClick.bind(this)
    this.wait = this.wait.bind(this)
    this.submit = this.submit.bind(this)
    this.contact = this.contact.bind(this)

    this.$form.addEventListener('submit', this.submit)

    this.$completeContent.forEach(($elm) => {
      $elm.addEventListener('click', this.modalContentClick)
    })
    this.$error?.addEventListener('click', this.errorClose)
    this.$complete?.addEventListener('click', this.close)
    this.$contactType?.addEventListener('change', this.contact)
  }

  contact() {
    const IF_GENERAL = this.$contactType.value === 'お問い合わせ（一般）'
    const show = IF_GENERAL ? 'general' : 'company'
    let categoryArray = [`<option value="" hidden>選択してください</option>`]

    if (IF_GENERAL) {
      this.formType = this.$form.dataset.form = 'contact'
    } else {
      this.formType = this.$form.dataset.form = 'contactCompany'
    }

    window.contactCategories[show].forEach((value) => {
      categoryArray.push(`<option value="${value}">${value}</option>`)
    })

    this.$contactTypeCategory.innerHTML = categoryArray.join('')

    this.$contactTypeSelect.forEach(($elm) => {
      if ($elm.dataset.typeSelect === show) {
        $elm.hidden = false
        $elm.style.display = ''
      } else {
        $elm.hidden = true
        $elm.style.display = 'none'
      }
    })
  }

  submit(event) {
    event.preventDefault()
    const formData = new FormData(this.$form)
    let jsonData = {}
    for (let [key, value] of formData.entries()) {
      if (key in jsonData) {
        jsonData[key] = `${jsonData[key]}、${value}`
      } else {
        jsonData[key] = value
      }
    }

    jsonData['送信日時'] = this.formatCurrentDate()
    jsonData['info'] = decodeURI(this.parameter().info || '')
    // console.log('submit')
    jsonData = {
      contactType: this.formType,
      content: jsonData
    }

    console.log(jsonData)

    const url = 'https://litpla-api.testup.website/api/api.php'

    if (this.IF_SENDING) return
    this.IF_SENDING = true
    this.$submit.disabled = true
    this.fadeIn(this.$sending)

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
      .then((response) => response.text())
      .then(async (data) => {
        // console.log(data)
        await this.fadeOut(this.$sending)
        if (data === 'メールを送信しました！') {
          await this.fadeIn(this.$complete)
        } else {
          await this.fadeIn(this.$error)
        }
      })
      .catch((error) => console.error('ERROR: ', error))
  }

  modalContentClick(event) {
    event.stopPropagation()
  }

  async fadeIn($elm) {
    return gsap.fromTo(
      $elm,
      {
        opacity: 0
      },
      {
        duration: 0.4,
        opacity: 1,
        display: 'block'
      }
    )
  }

  async fadeOut($elm) {
    return gsap.to($elm, {
      duration: 0.4,
      opacity: 0,
      display: 'none'
    })
  }

  async errorClose() {
    this.IF_SENDING = false
    this.$submit.disabled = false
    gsap.to(this.$error, {
      duration: 1,
      opacity: 0,
      display: 'none'
    })
  }

  async close() {
    this.IF_SENDING = false
    this.$submit.disabled = false
    gsap.to(this.$complete, {
      duration: 1,
      opacity: 0,
      display: 'none'
    })
  }

  wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  formatCurrentDate() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const hours = String(currentDate.getHours()).padStart(2, '0')
    const minutes = String(currentDate.getMinutes()).padStart(2, '0')
    const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}`
    return formattedDate
  }

  parameter() {
    let v = []
    const url = location.href.replace(/#.*$/, '')
    const hashes = url.slice(window.location.href.indexOf('?') + 1).split('&')
    for (let hash of hashes) {
      const h = hash.split('=')
      v.push(h[0])
      v[h[0]] = h[1]
    }
    return v
  }
}
