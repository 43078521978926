import detect from '../../core/detect'
import Hero from './Hero';

export default class Home {
  constructor() {
    new Hero();

    this.$home = document.querySelector('.page-top')
    if(!this.$home) return;

    if (!detect.isMobile()) {
      ScrollTrigger.normalizeScroll(true);
      window.addEventListener('wheel', this.handleWheelEvent.bind(this));
    }
  }
  
  handleWheelEvent(event) {
    if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      ScrollTrigger.normalizeScroll(false);
      setTimeout(() => ScrollTrigger.normalizeScroll(true), 1000);
    }
  }
}