export default class YtPlayer {
  get CLASSES() {
    return {
      isPlaying: 'is-playing'
    }
  }

  constructor() {
    this.$players = [...document.querySelectorAll('[data-youtube]')]
    if (!this.$players) return
    // this.player
    // this.loadIFramePlayerAPI()
    // this.onClick()
    this.init()
  }

  async init() {
    await this.loadIFramePlayerAPI()
    this.createPlayer()
  }

  async loadIFramePlayerAPI() {
    return new Promise((resolve) => {
      if (window.YT) {
        resolve()
      } else {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        window.onYouTubeIframeAPIReady = () => {
          resolve()
        }
      }
    })
  }

  createPlayer() {
    this.$players.forEach(($elm) => {
      const $movie = $elm.querySelector('[data-youtube-id]')
      $elm.player = new YT.Player($movie, {
        videoId: $movie.dataset.youtubeId
      })
      this.onClick($elm)
    })
  }

  onPlayerReady(event) {
    event.target.playVideo()
  }

  onClick($elm) {
    $elm.addEventListener('click', () => {
      $elm.classList.add(this.CLASSES.isPlaying)
      $elm.player.playVideo()
    })
  }

  // pause() {
  //   if (this.slider) {
  //     this.slider.on('slideChange', () => {
  //       if (this.player && this.player.getPlayerState() === YT.PlayerState.PLAYING) {
  //         this.player.pauseVideo();
  //       }
  //       this.$players.forEach(youtube => {
  //         if (youtube.classList.contains(this.CLASSES.isPlaying)) {
  //           youtube.classList.remove(this.CLASSES.isPlaying);
  //         }
  //       });
  //     });
  //   }
  // }
}
