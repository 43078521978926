import { config } from '../core';
import matchMedia from '../helpers/matchMedia';

export default class AdjustHeight {
  constructor() {
    this.$target = [...document.querySelectorAll('[data-adjust-height="target"]')]
    if (!this.$target.length) return;
    this.init();
    this.onResize();
  }

  init() {
    matchMedia(config.breakPoint.sp, (matches) => {
      if (matches) { 
        this.$target.forEach(element => {
          const source = element.querySelector('[data-adjust-height="source"]');
          const height = Math.round(source.offsetHeight * 0.8);
          element.style.height = `${height}px`;
        });
      }
    })
  }

  onResize() {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.$target.forEach(element => {
          element.style.height = null;
        });
        this.init();
      }, 500);
    });
  }
}