import { config } from '../../core';
import { customEase } from '../../helpers/customEase';

export default class Hero {
  constructor() {
    this.$hero = document.querySelector('[data-hero]')
    if(!this.$hero) return;
    this.$copyGroup01 = document.querySelector('[data-hero-el="copy-group1"]')
    this.$copyGroup02 = document.querySelector('[data-hero-el="copy-group2"]')
    this.$subCopy = document.querySelector('[data-hero-el="sub-copy"]')
    this.$navContainer = document.querySelector('[data-header-el="nav-container"]')
    this.$parkListBtn = document.querySelector('[data-park-list-btn]')
    this.$slider = document.querySelector('[data-hero-el="slider"]')
    this.$sliderPagination = document.querySelector('[data-hero-el="slider-pagination"]')
    this.mobile = window.innerWidth < config.viewport.sm
    this.slider;
    this.setup();
    this.init();
  }

  init() {
    window.addEventListener('load', () => {
      this.opening();
      this.createSlider();
    });
  }

  setup() {
    gsap.set(this.$copyGroup01, { autoAlpha: 0, y: -20 })
    gsap.set(this.$copyGroup02, { autoAlpha: 0, y: 20 })
    gsap.set(this.$subCopy, { autoAlpha: 0 })
    gsap.set(this.$navContainer, { yPercent: this.mobile ? 0 : -100 })
    gsap.set(this.$parkListBtn, { yPercent: this.mobile ? 0 : 120 })
    gsap.set(this.$sliderPagination, { autoAlpha: 0 })
  }

  opening() {
    const tl = gsap.timeline({
      defaults: {
        ease: customEase.quadOut,
        onComplete: () => this.slider.autoplay.start()
      }
    });

    tl
      .to([this.$copyGroup01, this.$copyGroup02], {
        autoAlpha: 1,
        y: 0,
        duration: 0.5,
      }, '0.5')

      .to(this.$subCopy, {
        autoAlpha: 1,
        duration: 0.5,
      }, '-=0.166')

      .to([this.$navContainer, this.$parkListBtn], {
        yPercent: 0,
        duration: 0.5,
        ease: customEase.circOut,
      })

      .to(this.$sliderPagination, {
        autoAlpha: 1,
        duration: 0.5,
        ease: customEase.circOut,
      }, '<0.13')
  }

  createSlider() {
    this.slider = new Swiper(this.$slider, {
      wrapperClass: 'kv-slider-item-list',
      slideClass: 'kv-slider-item',
      loop: true,
      speed: 1330,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      freeMode: false,
      slidesPerView: 'auto',
      effect: 'fade',
      allowTouchMove: false,
      pagination: {
        el: '.kv-slider-dot-list',
        bulletActiveClass: 'is-active',
        clickable: true,
      },
    });
    this.slider.autoplay.stop()
  }
}
