export default class TagView {
  constructor() {
    this.$tag = document.querySelector(`[data-tag="tag"]`)
    if (!this.$tag) return
    this.$button = document.querySelector(`[data-tag="button"]`)
    this.$button.addEventListener('click', this.view.bind(this))
  }

  view() {
    gsap.to(this.$tag, {
      height: 'auto',
      duration: 0.3,
      ease: 'power2.out'
    })
    gsap.to(this.$button, {
      opacity: 0,
      duration: 0.3,
      ease: 'power2.out',
      display: 'none'
    })
  }
}
