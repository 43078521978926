export default class LocalNav {
  get ELEMENTS() {
    return {
      nav: '[data-local-nav]',
    };
  }

  get CLASSES() {
    return {
      isLocated: 'is-located',
      isInvert: 'is-invert',
    }
  }

  get OPTIONS() {
    return {
      rootMargin: '-50% 0px',
    }
  }

  constructor() {
    this.$nav = document.querySelector('[data-local-nav]')
    if(!this.$nav) return;
    this.$observerTargets = [...document.querySelectorAll('[data-observer]')]
    this.io = new IntersectionObserver(this.callback.bind(this), this.OPTIONS);
    this.$observerTargets.forEach(target => this.io.observe(target))
  }

  callback(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const currentLocatedIndex = document.querySelector(`${this.ELEMENTS.nav} .${ this.CLASSES.isLocated }`);

        if (currentLocatedIndex !== null) {
          currentLocatedIndex.classList.remove(this.CLASSES.isLocated);
        }

        if (entry.target.id) {
          const newLocatedIndex = document.querySelector(`a[href='#${ entry.target.id }']`);
          const item = newLocatedIndex.parentNode;
          item.classList.add(this.CLASSES.isLocated);
        }
      }
    });
  }
}