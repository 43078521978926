import lottie from 'lottie-web'
import { config } from '../core/'

class Lottie {
  constructor() {
    this.animations = {}
  }

  createLottie(el) {
    const lottieId = el.dataset.animateId
    const lottieLoop = el.dataset.animateLoop

    this.animations[lottieId] = {
      lottie: lottie.loadAnimation({
        container: el,
        renderer: 'svg',
        loop: lottieLoop ?? true,
        autoplay: false,
        path: `${config.path.json}${lottieId}.json`
      }),
    }
  }
}

export default Lottie;
