export default class HoverVideo {
  constructor() {
    this.$elements = [...document.querySelectorAll('[data-hover-video]')];
    if (!this.$elements.length) return;
    this.init();
  }

  init() {
    this.$elements.forEach(element => {
      const video = element.querySelector('[data-hover-video-player]');
      if (!video) return;
      
      element.addEventListener('mouseenter', () => {
        video.play();
      });

      element.addEventListener('mouseleave', () => {
        video.pause();
        video.currentTime = 0;
        video.load();
      });
    });
  }
}