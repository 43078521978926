export default class Dropdown {
  get CLASSES() {
    return {
      isOpened: 'is-dropnav-opened',
    };
  }

  constructor() {
    this.$trigger = [...document.querySelectorAll('[data-dropdown-trigger]')];
    if (!this.$trigger) return;
    this.init();
  }

  init() {
    this.$trigger.forEach(element => {
      element.addEventListener('mouseover', () => this.handleMouseOver());
      element.addEventListener('mouseout', () => this.handleMouseOut());
    });
  }

  handleMouseOver() {
    if (!document.documentElement.classList.contains(this.CLASSES.isOpened)) {
      document.documentElement.classList.add(this.CLASSES.isOpened);
    }
  }

  handleMouseOut() {
    if (document.documentElement.classList.contains(this.CLASSES.isOpened)) {
      document.documentElement.classList.remove(this.CLASSES.isOpened);
    }
  }
}
