const matchMedia = (queryStr, fn) => {
  const mq = window.matchMedia(queryStr)
  const listener = (e) => fn(e.matches)
  mq.addListener(listener)
  fn(mq.matches)
  return {
    destroy: () => {
      mq.removeListener(listener)
    }
  }
}

export default matchMedia
