export default class Slider {
  get DEFAULT_OPTIONS() {
    return {
      wrapperClass: 'slides',
      slideClass: 'slide',
      slideActiveClass: 'is-selected',
      speed: 600,
      slidesPerView: 'auto',
      navigation: {
        prevEl: '.slider-btn-prev',
        nextEl: '.slider-btn-next',
      },
    };
  }

  constructor() {
    this.$sliders = [...document.querySelectorAll('[data-slider]')]
    if (!this.$sliders.length) return;
    this.createSlider();
  }

  createSlider() {
    this.$sliders.forEach((el) => {
      const sliderId = el.dataset.sliderId;
      const options = {
        ...this.DEFAULT_OPTIONS,
        loop: el.dataset.sliderLoop ? true : false,
        centeredSlides: el.dataset.sliderCentered ? true : false,
        pagination: {
          el: `[data-slider-id="${sliderId}"] .slider-paginations`,
          bulletActiveClass: 'is-active',
          clickable: true,
        },
        scrollbar: {
          el: `[data-slider-id="${sliderId}"] .slider-scroll`,
          draggable: true,
        },
      }
      new Swiper(el, options);
    });
  }
}