
import { BodyScrollLock } from '../modules/BodyScrollLock';

export default class Menu {
  get CLASSES() {
    return {
      isMenuOpened: 'is-menu-opened',
      isSubMenuOpened: 'is-subnav-opened',
    };
  }

  constructor() {
    this.$menu = document.querySelector('[data-menu]');
    if (!this.$menu) return;
    this.$bg = document.querySelector('[data-menu-bg]');
    this.$content = document.querySelector('[data-menu-content]');
    this.$trigger = document.querySelector('[data-menu-trigger]');
    this.$close = [...document.querySelectorAll('[data-menu-close]')];
    this.$btn = document.querySelector('[data-menu-btn]');
    this.$subMenuTrigger = [...document.querySelectorAll('[data-sub-menu-trigger]')];
    this.$subMenu;
    this.init();
  }

  init() {
    this.setup();
    this.onTriggerClicked();
    this.onCloseClicked();
    this.onSubMenuTriggerClicked();
  }

  setup() {
    gsap.set(this.$content, { xPercent: 100 })
    gsap.set([this.$menu, this.$bg, this.$btn], { autoAlpha: 0 })
  }

  onTriggerClicked() {
    this.$trigger.addEventListener('click', () => {
      document.documentElement.classList.contains(this.CLASSES.isMenuOpened) ? this.hide() : this.show();
    });
  }

  show() {
    BodyScrollLock(true);
    this.$menu.scrollTop = 0;
    document.documentElement.classList.add(this.CLASSES.isMenuOpened);
    const showTl = gsap.timeline({ defaults: { duration: 0.25, ease: 'none' }});
    showTl
      .to([ this.$menu, this.$bg ], {
        autoAlpha: 1,
      })
      .to(this.$content, {
        xPercent: 0,
        duration: 0.5,
        ease: 'power2.out',
      }, '<')
      .to(this.$btn, {
        autoAlpha: 1,
        onComplete: () => {
          ScrollTrigger.normalizeScroll(false);
        }
      }, '+=0')
  }

  hide() {
    BodyScrollLock(false);
    const hideTl = gsap.timeline({ defaults: { duration: 0.25, ease: 'none' }});
    hideTl
      .to(this.$content, {
        xPercent: 100,
        duration: 0.5,
        ease: 'power2.out',
      })
      .to([this.$bg, this.$btn], {
        autoAlpha: 0,
      }, '<')
      .to(this.$menu, {
        autoAlpha: 0,
        onComplete: () => {
          document.documentElement.classList.remove(this.CLASSES.isMenuOpened);
          ScrollTrigger.normalizeScroll(true);
        }
      })
  }

  onCloseClicked() {
    this.$close.forEach((element) => {
      element.addEventListener('click', () => this.hide());
    });
  }

  onSubMenuTriggerClicked() {
    this.$subMenuTrigger.forEach(el => {
      el.addEventListener('click', () => {
        this.$subMenu = el.querySelector('[data-sub-menu]');
        this.subMenuToggle(el, this.$subMenu);
      });
    });
  }

  subMenuToggle(trigger, subMenu) {
    trigger.classList.contains(this.CLASSES.isSubMenuOpened) ? this.subMenuClosed(trigger, subMenu) : this.subMenuOpened(trigger, subMenu);
  }

  subMenuOpened(trigger, subMenu) {
    trigger.classList.add(this.CLASSES.isSubMenuOpened);
    gsap.to(subMenu, {
      height: 'auto',
      duration: 0.4,
      ease: 'power2.out"',
    })
  }

  subMenuClosed(trigger, subMenu) {
    trigger.classList.remove(this.CLASSES.isSubMenuOpened);
    gsap.to(subMenu, {
      height: 0,
      duration: 0.4,
      ease: 'power2.out"',
    })
  }
}
