export default class BusinessSlider {
  get DEFAULT_OPTIONS() {
    return {
      wrapperClass: 'slides',
      slideClass: 'slide',
      slideActiveClass: 'is-selected',
      loop: true,
      speed: 5000,
      slidesPerView: 'auto',
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },  
    };
  }

  constructor() {
    this.$slider = document.querySelector('[data-business-slider]')
    if(!this.$slider) return;
    this.createSlider();
  }

  createSlider() {
    new Swiper(this.$slider, this.DEFAULT_OPTIONS);
  }
}