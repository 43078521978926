import { config } from '../../core';
import matchMedia from '../../helpers/matchMedia';

export default class CustomHeader {
  get CLASSES() {
    return {
      isFixed: 'is-header-fixed',
      isShow: 'is-scroll-top',
      isHide: 'is-hide'
    };
  }

  constructor() {
    this.$header = document.querySelector('[data-custom-header]');
    if (!this.$header) return;
    this.$subpageHead = document.querySelector('[data-subpage-head]');
    this.currentWidth = window.innerWidth;
    this.newWidth;
    this.options = {
      showDelay: 200,
      startPosition: 0,
      lastPosition: 0,
      upFlg: false,
      setTimeoutFlg: false,
      setTimeoutId: 0,
    };
    this.init();
    this.onResize();
  }

  init() {
    this.scrollTrigger();

    matchMedia(config.breakPoint.sp, (matches) => {
      if (matches) { 
        this.setFixedOnScroll();
      }
    })    
  }

  scrollTrigger() {
    ScrollTrigger.create({
      trigger: this.$header,
      start: 'bottom top',
      onEnter: () => {
        this.addClass();
      },
      onLeaveBack: () => {
        this.removeClass();
      }
    });
  }

  setFixedOnScroll() {
    window.addEventListener('scroll', () => {
      const scrollTop = window.scrollY;

      if (scrollTop > this.options.lastPosition) {
        this.options.upFlg = false;
        clearTimeout(this.options.setTimeoutId);
        this.options.setTimeoutFlg = false;

        if (scrollTop > this.$header.clientHeight) {
          document.documentElement.classList.add(this.CLASSES.isFixed);
        }

        const pageHeight = document.documentElement.scrollHeight;
        const scrollBottom = window.innerHeight + scrollTop;

        if (pageHeight <= scrollBottom) {
          this.showHeader();
        } else {
          if (document.documentElement.classList.contains(this.CLASSES.isShow)) {
            this.hideHeader();
          }
        }
      } else if (scrollTop < this.options.lastPosition) {
        this.options.upFlg = true;

        if (scrollTop <= 0) {
          document.documentElement.classList.remove(this.CLASSES.isFixed, this.CLASSES.isShow, this.CLASSES.isHide);
        } else {
          if (!this.options.setTimeoutFlg) {
            this.options.setTimeoutFlg = true;
            this.options.setTimeoutId = setTimeout(() => {
              if (this.options.upFlg && document.documentElement.classList.contains(this.CLASSES.isFixed)) {
                this.showHeader();
              }
            }, this.options.showDelay);
          }
        }
      }
      this.options.lastPosition = scrollTop;
    });
  }

  showHeader() {
     document.documentElement.classList.remove(this.CLASSES.isHide);
     document.documentElement.classList.add(this.CLASSES.isShow);
  }

  hideHeader() {
     document.documentElement.classList.remove(this.CLASSES.isShow);
     document.documentElement.classList.add(this.CLASSES.isHide);
  }

  addClass() {
    if (!document.documentElement.classList.contains(this.CLASSES.isFixed)) {
      document.documentElement.classList.add(this.CLASSES.isFixed);
    }
  }

  removeClass() {
    if (document.documentElement.classList.contains(this.CLASSES.isFixed)) {
      document.documentElement.classList.remove(this.CLASSES.isFixed);
    }
  }

  reset() {
    this.removeClass();
    document.documentElement.classList.remove(this.CLASSES.isHide);
    document.documentElement.classList.remove(this.CLASSES.isShow);
    this.init();
  }

  onResize() {
    window.addEventListener('resize', () => {
      this.newWidth = window.innerWidth;
      if ((this.currentWidth >= config.viewport.md && this.newWidth < config.viewport.md) || (this.currentWidth <= config.viewport.sm && this.newWidth > config.viewport.sm)) {
        this.reset();
      }
      this.currentWidth = this.newWidth;
    });
  }
}