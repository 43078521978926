import Lottie from '../modules/Lottie';

export default class ScrollAnimation {
  constructor() {
    this.$elements = [...document.querySelectorAll('[data-animate]')]
    if(!this.$elements.length) return;
    this.lottie = new Lottie();
    this.init();
  }

  init() {
    this.$elements.forEach(el => {
      switch (el.dataset.animate) {
        case 'heading':
          this.animateHeading(el);
          break;
        case 'slide-in':
          this.slideIn(el);
          break;
        case 'bg':
          this.animateBg(el);
          break;
        case 'bg-text':
          this.animateBgText(el);
          break;
      }
    });
  }

  animateHeading(el) {
    const tl = gsap.timeline();
    const lg = [...el.querySelectorAll('[data-heading-el="lg"]')]
    const sm = [...el.querySelectorAll('[data-heading-el="sm"]')]
    gsap.set(lg, { yPercent: 100 })
    gsap.set(sm, { autoAlpha: 0 })
    ScrollTrigger.create({
      trigger: el,
      start: el.dataset.triggerStart ?? 'top 90%',
      onEnter: () => {
        tl
          .to(lg, {
            yPercent: 0,
            duration: el.dataset.duration ?? 0.5,
            ease: el.dataset.ease ?? 'circ.out',
          })
          .to(sm, {
            autoAlpha: 1,
            duration: el.dataset.duration ?? 0.4,
            ease: 'none',
          })
      },
    });
  }

  animateBg(el) {
    this.lottie.createLottie(el);
    const lottieId = el.dataset.animateId;
    const playLottie = () => this.lottie.animations[lottieId]?.lottie.play();
    const stopLottie = () => this.lottie.animations[lottieId]?.lottie.stop();
    ScrollTrigger.create({
      trigger: el,
      start: el.dataset.triggerStart ?? 'top bottom',
      onEnter: playLottie,
      onEnterBack: playLottie,
      onLeave: stopLottie,
      onLeaveBack: stopLottie,
    });
  }

  animateBgText(el) {
    gsap.set(el, { yPercent: 100 })
    ScrollTrigger.create({
      trigger: el,
      start: el.dataset.triggerStart ?? 'top bottom',
      onEnter: () => {
        gsap.to(el, {
          yPercent: 0,
          duration: el.dataset.duration ?? 0.75,
          ease: 'power1.inOut',
        })
      },
    });
  }

  slideIn(el) {
    gsap.set(el, { xPercent: el.dataset.x ?? 100 })
    ScrollTrigger.create({
      trigger: el,
      start: el.dataset.triggerStart ?? 'top bottom',
      onEnter: () => {
        gsap.to(el, {
          xPercent: 0,
          duration: el.dataset.duration ?? 1.5,
          ease: el.dataset.ease ?? 'expo.out',
          delay: el.dataset.delay ?? 0,
        })
      },
    });
  }
}
