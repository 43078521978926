import { config } from '../core';
import matchMedia from '../helpers/matchMedia'
import AdjustHeight from './AdjustHeight';

export default class ExpandableList {
  constructor() {
    this.$expandableLists = [...document.querySelectorAll('[data-expandable-list]')];
    if (!this.$expandableLists.length) return;

    this.$expandableLists.forEach($expandableList => {
      const $items = [...$expandableList.querySelectorAll('[data-expandable-item]')];
      const $initialItemCountElement = $expandableList.querySelector('[data-initial-display-count]');
      const $showMoreBtn = $expandableList.querySelector('[data-show-more]');
      const initCount = parseInt($initialItemCountElement.getAttribute('data-initial-display-count'), 10);

      if ($items && $initialItemCountElement && $showMoreBtn) {
        new ExpandableListInstance($items, $showMoreBtn, initCount);
      }
    });
  }
}

class ExpandableListInstance {
  constructor($items, $showMoreBtn, initCount) {
    this.$items = $items;
    this.$showMoreBtn = $showMoreBtn;
    this.initCount = initCount;
    this.adjustHeight = new AdjustHeight();
    this.currentWidth = window.innerWidth;
    this.newWidth;
    this.init();
    this.onClick();
    this.onResize(); 
  }

  init() {
    matchMedia(config.breakPoint.sp, (matches) => {
      if (matches) { 
        this.$showMoreBtn.style.display = 'block';
        this.currentCount = this.initCount;
        this.hideExcessItems();
      } else {
        this.reset();
      }
    })
  }

  onClick() {
    this.$showMoreBtn.addEventListener('click', () => {
      this.showMoreItems();
      this.adjustHeight.init();
    });
  }

  hideExcessItems() {
    this.$items.forEach((item, index) => {
      if (index >= this.initCount) item.style.display = 'none';
    });
  }

  showMoreItems() {
    this.$items.forEach((item) => {
      item.style.display = 'block';
    });
    this.$showMoreBtn.style.display = 'none';
  }

  reset() {
    this.$items.forEach(item => {
      item.style.display = null;
    });
    this.$showMoreBtn.style.display = null;
  }

  onResize() {
    window.addEventListener('resize', () => {
      this.newWidth = window.innerWidth;
      if ((this.currentWidth >= config.viewport.md && this.newWidth < config.viewport.md) || (this.currentWidth <= config.viewport.sm && this.newWidth > config.viewport.sm)) {
        this.init();
      }
      this.currentWidth = this.newWidth;
    });
  }
}