import { config } from '../core';
import matchMedia from '../helpers/matchMedia';

export default class MobileSlider {
  get DEFAULT_OPTIONS() {
    return {
      wrapperClass: 'mobile-slides',
      slideClass: 'mobile-slide',
      slideActiveClass: 'is-selected',
      speed: 600,
      slidesPerView: 'auto',
      navigation: {
        prevEl: '.mobile-slider-btn-prev',
        nextEl: '.mobile-slider-btn-next',
      },
    };
  }

  constructor() {
    this.$sliders = [...document.querySelectorAll('[data-mobile-slider]')]
    if (!this.$sliders) return;
    this.sliders = [];
    this.createSlider();
  }

  createSlider() {
    matchMedia(config.breakPoint.sp, (matches) => {
      if (matches) { 
        this.$sliders.forEach(el => {
          let sliderId = el.dataset.sliderId;
          let options = {
            ...this.DEFAULT_OPTIONS,
            loop: el.dataset.sliderLoop ? true : false,
            centeredSlides: el.dataset.sliderCentered ? true : false,
            pagination: {
              el: `[data-slider-id="${sliderId}"] .slider-paginations`,
              bulletActiveClass: 'is-active',
              clickable: true,
            }
          }
          this.sliders.push(new Swiper(el, options));
        });
      } else {
        this.sliders.forEach(slider => {
          slider.destroy();
        });
        this.sliders = [];
      }
    })
  }
}