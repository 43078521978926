export default class Select {
  get COLORS() {
    return {
      defaultColor: '#999999',
      changeColor: '#000000',
    };
  }

  constructor() {
    this.$elements = [...document.querySelectorAll('[data-select]')];
    if(!this.$elements.length) return;
    this.init();
  }

  init() {
    this.$elements.forEach($element => {
      $element.defaultValue = $element.options[0].value;
      this.changeColor($element);
      $element.addEventListener('change', () => this.changeColor($element));
    });
  }

  changeColor($element) {
    if($element.value === $element.defaultValue) {
      $element.style.color = this.COLORS.defaultColor;
    } else {
      $element.style.color = this.COLORS.changeColor;
    }
  }
}