import ScrollHint from 'scroll-hint'

export default class Scrollable {
  get SELECTOR() {
    return {
      scrollable: '[data-scrollable]',
    };
  }

  constructor() {
    this.init();
  }

  init() {
    new ScrollHint(this.SELECTOR.scrollable, {
      i18n: {
        scrollable: 'スクロールできます'
      }
    });
  }
}
