const BodyScrollLock = (fixed) => {
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style.paddingRight = fixed ? `${ scrollbarWidth }px` : '';

  const styles = {
    overflow: 'hidden',
  };

  Object.keys(styles).forEach((key) => {
    document.documentElement.style[key] = fixed ? styles[key] : '';
  });
};

export { BodyScrollLock };