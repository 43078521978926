import jpHoli from 'japanese-holidays'

export default class BusinessHours {
  get situation() {
    return {
      0: `<div class="status-5"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-closed"/></svg><svg class="sp"><use xlink:href="#icon-closed-sp"/></svg></span><span class="status-text">開催前</span></div>`,
      1: `<div class="status-1"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-double-circle"/></svg><svg class="sp"><use xlink:href="#icon-double-circle-sp"/></svg></span><span class="status-text">快適に遊べます</span></div>`,
      2: `<div class="status-2"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-circle"/></svg><svg class="sp"><use xlink:href="#icon-circle-sp"/></svg></span><span class="status-text">やや混雑しています</span></div>`,
      3: `<div class="status-3"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-triangle"/></svg><svg class="sp"><use xlink:href="#icon-triangle-sp"/></svg></span><span class="status-text">混雑しています</span></div>`,
      4: `<div class="status-4"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-limited"/></svg><svg class="sp"><use xlink:href="#icon-limited-sp"/></svg></span><span class="status-text">入場制限中</span></div>`,
      5: `<div class="status-4"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-limited"/></svg><svg class="sp"><use xlink:href="#icon-limited-sp"/></svg></span><span class="status-text">入場制限中<span class="text-small">一時的にフリーパスの販売を<br class="pc">休止<br class="sp">しています</span></span></div>`,
      6: `<div class="status-4"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-limited"/></svg><svg class="sp"><use xlink:href="#icon-limited-sp"/></svg></span><span class="status-text">貸切営業中</span></div>`,
      7: `<div class="status-5"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-closed"/></svg><svg class="sp"><use xlink:href="#icon-closed-sp"/></svg></span><span class="status-text">開催終了</span></div>`,
      8: `<div class="status-4"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-limited"/></svg><svg class="sp"><use xlink:href="#icon-limited-sp"/></svg></span><span class="status-text">営業休止中</span></div>`,
      9: `<div class="status-5"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-closed"/></svg><svg class="sp"><use xlink:href="#icon-closed-sp"/></svg></span><span class="status-text">営業開始前</span></div>`
    }
  }

  constructor() {
    this.$id = document.querySelector('[data-park-id]')
    if (!this.$id) return

    this.$time = document.querySelector('[data-business="time"]')
    this.$status = document.querySelector('[data-business="status"]')
    this.now = new Date()
    this.y = this.now.getFullYear()
    this.m = this.now.getMonth()
    this.d = this.now.getDate()
    this.today = new Date(this.y, this.m, this.d, 9)
    const day = this.today.getDay()
    this.dayType =
      jpHoli.isHoliday(this.today) || day === 0 || day === 6
        ? 'holiday'
        : 'weekday'

    this.id = this.$id.dataset.parkId

    this.array = window.business_hours
    this.open = ''
    this.close = ''
    this.IF_SPECIAL = false

    if (this.id) {
      this.fetchAPI(this.id)
    } else {
      if (!this.$time || !this.array) return
      // this.init()
      this.$id.style.display = 'none'
    }
  }

  fetchAPI(id) {
    const url = `https://litpla-portal.appspot.com/api/stores/${id}/status?format=2`
    fetch(url)
      .then((res) => res.json())
      .then(async (data) => {
        const { openAt, closeAt, isTemporaryClosed } = data
        this.data = data
        this.open = openAt
        this.close = closeAt
        this.isTemporaryClosed = isTemporaryClosed
        console.log(data)
        this.setAPI()
      })
      .catch((error) => {
        console.error('ERROR: ', error)
        // this.init()
        this.$id.style.display = 'none'
      })
  }

  setAPI() {
    const { data, situation } = this
    const {
      isOpenPark,
      isOpenHour,
      isTemporaryClosed,
      temporaryClosedReason,
      state
    } = data
    let html = situation[state]

    this.setTime()

    if (isOpenPark === false && isOpenHour === true) {
      this.$status.style.display = 'none'
    }

    if (isTemporaryClosed) {
      console.log(temporaryClosedReason)
      html = `<div class="status-5"><span class="status-icon"><svg class="pc"><use xlink:href="#icon-closed"/></svg><svg class="sp"><use xlink:href="#icon-closed-sp"/></svg></span><span class="status-text">${temporaryClosedReason}</span></div>`
    }

    if (!isOpenHour) {
      html = situation[9]
    }

    this.$status.innerHTML = html
  }

  init() {
    const { today, dayType, array } = this
    this.special = array.specialday.period_setting
    this.weekday = array.weekday
    this.holiday = array.holiday

    if (this.special.length) {
      this.special.forEach((special) => {
        const start = new Date(special.start)
        const end = new Date(special.end)
        if (today >= start && today <= end && !this.IF_SPECIAL) {
          this.open = special['open']
          this.close = special['close']
          this.IF_SPECIAL = true
        }
      })
    }

    if (!this.IF_SPECIAL) {
      this.open = array[dayType]['open']
      this.close = array[dayType]['close']
    }

    this.setTime()

    const ot = this.open.split(':')
    const ct = this.close.split(':')
    const startDate = new Date(this.y, this.m, this.d, ot[0], ot[1])
    const closeDate = new Date(this.y, this.m, this.d, ct[0], ct[1])

    if (!(startDate < this.now && this.now < closeDate)) {
      this.$status.innerHTML = this.situation[9]
    }
  }

  setTime() {
    const { $time, open, close, isTemporaryClosed } = this
    let html = `${open} <span>～</span> ${close}`
    if (isTemporaryClosed) {
      html = `　　<span>－</span>　　`
    }
    $time.innerHTML = html
  }
}
