export default class UrlCopy {
  constructor() {
    this.$button = document.querySelectorAll(`[data-url-copy]`)
    this.$button.forEach(($elm) => {
      $elm.addEventListener('click', this.copy.bind(this))
    })
  }

  copy() {
    navigator.clipboard
      .writeText(location.href)
      .then(function () {
        console.log('COPY SUCCESS: ' + location.href)
      })
      .catch(function (err) {
        console.error('COPY ERROR: ', err)
      })
  }
}
