export default class FillHeight {
  constructor() {
    this.vw = window.innerWidth;
    this.init();
    this.onResize();
  }

  init() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  onResize() {
    window.addEventListener('resize', () => {
      if (this.vw === window.innerWidth) return;
      this.vw = window.innerWidth;
      this.init();
    });
  }
}
