import gsap from 'gsap/dist/gsap.min'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min'
import { CustomEase } from 'gsap/dist/CustomEase.min'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.min'
import Swiper from 'swiper/bundle'
import UAParser from 'ua-parser-js'
import SmoothScroll from 'ui/SmoothScroll'
import Loader from 'ui/Loader'
import Header from 'ui/Header'
import Dropdown from 'ui/Dropdown'
import Menu from 'ui/Menu'
import LocalNav from 'ui/LocalNav'
import FillHeight from 'ui/FillHeight'
import Accordion from 'ui/Accordion'
import SubpageOpening from 'ui/SubpageOpening'
import Parallax from 'ui/Parallax'
import ScrollAnimation from 'ui/ScrollAnimation'
import Slider from 'ui/Slider'
import MobileSlider from 'ui/MobileSlider'
import InfiniteCarousel from 'ui/InfiniteCarousel'
import YtPlayer from 'ui/YtPlayer'
import HoverVideo from 'ui/HoverVideo'
import Scrollable from 'ui/Scrollable'
import ExpandableList from 'ui/ExpandableList'
import Select from 'ui/Select'
import AdjustHeight from 'ui/AdjustHeight'
import DatePicker from 'ui/DatePicker'
import Home from 'pages/Home'
import Space from 'pages/Space'
import Blog from 'pages/Blog'
import Contact from 'pages/Contact'
import Business from 'pages/Business'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

window.gsap = gsap
window.ScrollTrigger = ScrollTrigger
window.ScrollToPlugin = ScrollToPlugin
window.CustomEase = CustomEase
window.Swiper = Swiper

const parser = new UAParser()
const model = parser.getDevice().model
if (model === 'iPad') {
  document
    .querySelector('meta[name=viewport]')
    .setAttribute('content', 'width=375')
  document.documentElement.classList.add(`is-iPad`)
}

window.addEventListener('DOMContentLoaded', () => {
  new SmoothScroll()
  new Loader()
  new Header()
  new Dropdown()
  new Menu()
  new LocalNav()
  new FillHeight()
  new Accordion()
  new SubpageOpening()
  new Parallax()
  new ScrollAnimation()
  new Slider()
  new MobileSlider()
  new InfiniteCarousel()
  new YtPlayer()
  new HoverVideo()
  new Scrollable()
  new ExpandableList()
  new Select()
  new AdjustHeight()
  new DatePicker()
  new Home()
  new Space()
  new Blog()
  new Contact()
  new Business()
})
