export default class Parallax {
  constructor() {
    this.$elements = document.querySelectorAll('[data-parallax]')
    if(!this.$elements.length) return;
    this.init()
  }

  init() {
    this.$elements.forEach(el => {
      switch (el.dataset.parallax) {
        case 'y':
          this.parallaxY(el)
          break;
          case 'scale':
            this.parallaxScale(el)
            break;
          case 'text':
            this.parallaxText(el)
            break;
      }
    });
  }

  parallaxY(el) {
    gsap.set(el, { yPercent: el.dataset.parallaxStartY ?? 10 })
    gsap.to(el, {
      yPercent: el.dataset.parallaxEndY ?? -10,
      ease: 'none',
      scrollTrigger: {
        trigger: el,
        start: el.dataset.triggerStart ?? 'top bottom',
        scrub: el.dataset.scrub ?? 1,
      }
    })
  }

  parallaxScale(el) {
    gsap.set(el, { scale: el.dataset.parallaxScale ?? 1.1 })
    gsap.to(el, {
      scale: 1,
      rotate: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: el,
        end: 'bottom 50%',
        scrub: el.dataset.scrub ?? 1,
      }
    })
  }

  parallaxText(el) {
    gsap.set(el, { xPercent: el.dataset.startX ?? 10 })
    gsap.to(el, {
      xPercent: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: el,
        start: 'top bottom',
        end: 'bottom 50%',
        scrub: 3,
      },
    });
  }
}
