export const customEase = {
  quadOut: CustomEase.create('quadOut', '0.5, 1, 0.89, 1'),
  cubicOut: CustomEase.create('cubicOut', '0.33, 1, 0.68, 1'),
  quartOut: CustomEase.create('quartOut', '0.25, 1, 0.5, 1'),
  quartInOut: CustomEase.create('quartOut', '0.76, 0, 0.24, 1'),
  quintOut: CustomEase.create('quintOut', '0.22, 1, 0.36, 1'),
  expoOut: CustomEase.create('expoOut', '0.16, 1, 0.3, 1'),
  expoInOut: CustomEase.create('expoInOut', '0.87, 0, 0.13, 1'),
  circOut: CustomEase.create('expoOut', '0, 0.55, 0.45, 1'),
  circInOut: CustomEase.create('expoInOut', '0.85, 0, 0.15, 1'),
}