import { config } from '../core/'

export default class SubpageOpening {
  constructor() {
    this.$head = document.querySelector('[data-subpage-head]')
    if(!this.$head) return;
    this.$heading = [...this.$head.querySelectorAll('[data-subpage-head-el="heading"]')];
    this.$breadcrumb = this.$head.querySelector('[data-subpage-head-el="breadcrumb"]')
    this.mobile = window.innerWidth < config.viewport.sm
    this.setup();
    window.addEventListener('load', () => {
      setTimeout(() => this.init(), 250);
    });
  }

  setup() {
    gsap.set(this.$heading, { autoAlpha: 0, y: this.mobile ? 50 * 0.5 : 50 })
    gsap.set(this.$breadcrumb, { autoAlpha: 0 })
  }

  init() {
    this.opening();
  }
  
  opening() {
    const openingTl = gsap.timeline({ defaults: { ease: 'power4.out', duration: 0.85 } });
    openingTl
      .to(this.$heading, {
        autoAlpha: 1,
        y: 0,
        stagger: { 
          each: 0.15,
        },
      })
      .to(this.$breadcrumb, {
        autoAlpha: 1,
        duration: '0.4',
        ease: 'none',
      }, '-=0.2')
  }
}
