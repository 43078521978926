import { BodyScrollLock } from '../modules/BodyScrollLock';

export default class Header {
  get CLASSES() {
    return {
      isFixed: 'is-header-fixed',
      isOpened: 'is-dropnav-opened',
    };
  }

  constructor() {
    this.$header = document.querySelector('[data-header]');
    if (!this.$header) return;
    this.$hero = document.querySelector('[data-hero]');
    this.$subpageHead = document.querySelector('[data-subpage-head]');
    this.$tickets = document.querySelector('[data-tickets]');
    this.$ticketsBtns = [...document.querySelectorAll('[data-tickets-el]')];
    this.$languageTrigger = document.querySelector('[data-language-trigger]');
    this.dataAttrValue;
    this.isProcessing = false;
    this.init();
  }

  init() {
    this.scrollTrigger();
    this.ticketsMouseEvents();
    this.onLanguageClicked();
  }

  scrollTrigger() {
    const custom = this.$header.hasAttribute('data-custom-header')
    if (custom) return;

    ScrollTrigger.create({
      trigger: this.$header,
      start: 'bottom top',
      onEnter: () => {
        if (!document.documentElement.classList.contains(this.CLASSES.isFixed)) {
          document.documentElement.classList.add(this.CLASSES.isFixed);
        }
      },
      onLeaveBack: () => {
        if (document.documentElement.classList.contains(this.CLASSES.isFixed)) {
          document.documentElement.classList.remove(this.CLASSES.isFixed);
        }
      }
    });
  }

  ticketsMouseEvents() {
    this.$ticketsBtns.forEach(el => {
      this.dataAttrValue = el.dataset.ticketsEl
      el.addEventListener('mouseover', () => this.ticketsMouseOver(el));
      el.addEventListener('mouseout', () => this.ticketsMouseOut(el));
    });
  }

  ticketsMouseOver(el) {
    this.dataAttrValue = el.dataset.ticketsEl
    this.$tickets.classList.add(`is-hover-${this.dataAttrValue}`);
  }

  ticketsMouseOut(el) {
    this.dataAttrValue = el.dataset.ticketsEl
    this.$tickets.classList.remove(`is-hover-${this.dataAttrValue}`);
  }

  onLanguageClicked() {
    this.$languageTrigger.addEventListener('click', () => {
      if (this.isProcessing) return; 
      this.isProcessing = true;
      this.$languageTrigger.classList.contains(this.CLASSES.isOpened) ? this.languageHide() : this.languageShow();
    });
  }

  languageShow() {
    BodyScrollLock(true);
    ScrollTrigger.normalizeScroll(false);
    this.$languageTrigger.classList.add(this.CLASSES.isOpened);
    setTimeout(() => {
      this.isProcessing = false; 
    }, 200);
  }

  languageHide() {
    BodyScrollLock(false);
    ScrollTrigger.normalizeScroll(true);
    this.$languageTrigger.classList.remove(this.CLASSES.isOpened);
    setTimeout(() => {
      this.isProcessing = false;
    }, 200);
  }
}
