export default class Validate {
  constructor() {
    this.$form = document.querySelector(`[data-validate]`)

    if (!this.$form) return
    this.errorArray = []
    this.check = this.check.bind(this)
    this.$submit = this.$form.querySelector(`[data-validate-submit]`)
    this.$required = this.$form.querySelectorAll(`[data-validate-required]`)
    this.$input = this.$form.querySelectorAll('input, select, textarea')
    this.$submit.addEventListener('click', this.onClickSubmit.bind(this))
    this.$input.forEach(($elm) => {
      $elm.addEventListener('change', this.check)
      $elm.addEventListener('keyup', this.check)
    })
  }

  onClickSubmit(event) {
    if (this.errorArray.length !== 0) {
      event.preventDefault()
      this.scrollTo(this.errorArray[0])
    }
  }

  check() {
    const { $required } = this
    this.errorArray = []

    $required.forEach(($elm) => {
      const $input = $elm.querySelectorAll('input, select, textarea')
      let obj = {
        elm: $elm,
        value: []
      }

      let emailMaster
      let emailConfirm
      let checkbox

      $input.forEach(($input) => {
        if ($input.type === 'email') {
          if (!$input.value) {
            obj.value.push(false)
          }

          if ($input.dataset.email === 'master') {
            emailMaster = $input.value
          } else {
            emailConfirm = $input.value
          }
        } else if ($input.type === 'checkbox') {
          checkbox = 'checkbox'
          obj.value.push($input.checked)
        } else {
          if (!$input.value) {
            obj.value.push(false)
          }
        }
      })

      if (checkbox === 'checkbox' && obj.value.indexOf(true) !== -1) {
        obj.value = []
      }

      // email check
      if (emailMaster !== emailConfirm) {
        obj.value.push(false)
      }

      if (obj.value.length) {
        this.errorArray.push(obj.elm)
      }
    })

    if (this.errorArray.length !== 0) {
      this.$submit.disabled = true
    } else {
      this.$submit.disabled = false
    }
  }

  scrollTo(elm) {
    gsap.to(window, {
      duration: 0.75,
      scrollTo: {
        y: elm,
        offsetY: 80,
        autoKill: false
      },
      ease: 'power3.out'
    })
  }
}
