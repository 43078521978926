const config = {
  viewport: {
    sm: '767',
    md: '768',
  },
  breakPoint: {
    sp: '(max-width: 767px)',
    pc: '(min-width: 768px)',
  },
  path: {
    json: '/wp-content/themes/litpla/assets/js/data/',
  },
}

export default config
